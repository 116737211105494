import { ApiResponse, api, Page } from "@teppixdigital/xapishared";
import { FeedItem } from "../domain";

export const getContents = async (
  cursor?: string,
  limit?: number
): Promise<ApiResponse<Page<FeedItem>>> => {
  const resp = await api.get<{}, Page<FeedItem>>(`/feeds/posts`, {
    queryParams: {
      cursor,
      limit,
    },
  });
  return resp;
};
