import { ApiResponse, api } from "@teppixdigital/xapishared";
import { FeedItem } from "../domain";

export const getContentDetail = async (
  id: string,
  path: string
): Promise<ApiResponse<FeedItem>> => {
  const resp = await api.get<{}, FeedItem>(`/feeds/posts/${id}/-/${path}`);
  return resp;
};
