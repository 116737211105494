import React, { useEffect, useState } from "react";
import {
  CardPulse,
  JsonNodePresenter,
  Prose,
} from "@teppixdigital/shared-cms-components";

import { FeedItem } from "../../../domain";
import { RenderZone } from "../render_zone/render_zone.component";
import { getContentDetailByUri, gtm_manager } from "../../../use_case";

export const FeedItemDetailComponent = (props: { item: FeedItem }) => {
  const { item } = props;

  const [feed, setFeed] = useState<FeedItem | null>();

  useEffect(() => {
    const load = async (): Promise<void> => {
      const { detail_uri = null } = item.additions || {};
      if (detail_uri) {
        const resp = await getContentDetailByUri(detail_uri);
        if (resp.data) {
          gtm_manager.pushFeedItemModelViewedEvent(resp.data);
          setFeed(resp.data);
        }
      }
    };
    load();
  }, [item]);

  return (
    <>
      {feed ? (
        <RenderZone>
          <JsonNodePresenter node={feed.body} context={{ mode: "public" }} />
        </RenderZone>
      ) : (
        <CardPulse />
      )}
    </>
  );
};
