import React from "react";
import { Router } from "@teppixdigital/shared-router";
import { FeedItemDetailFlow, FeedItemListFlow } from "../flow";
import { routes } from "../routes";

export interface ContentClientJourneyProps {}

export const ContentClientJourney = (props: ContentClientJourneyProps) => {
  return (
    <Router>
      <FeedItemDetailFlow
        path={routes.feedItemDetailRoute(":item_id", ":item_path")}
      />
      <FeedItemListFlow path={routes.feedItemListRoute()} />
      <FeedItemListFlow path="/" />
    </Router>
  );
};
