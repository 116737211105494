import React, { useMemo } from "react";
import {
  Plugin,
  PostTitle,
  PostSubTitle,
  JsonNodePresenter,
  JsonNodePresenterProps,
  PostBody,
  Column,
} from "@teppixdigital/shared-cms-components";

import { Content } from "../../../domain";

export const PostDetailComponent = (props: { content: Content }) => {
  const { content } = props;
  return (
    <Column space_between>
      <PostTitle>
        {() => <div className={`post_title_pos`}>{content.title}</div>}
      </PostTitle>
      <PostSubTitle>{() => <>{content.summary}</>}</PostSubTitle>
      <PostBody>
        {() => (
          <>
            {content.content ? (
              <JsonNodePresenter
                node={{ k: "doc", c: content.content }}
                context={{ mode: "public" }}
              />
            ) : null}
          </>
        )}
      </PostBody>
    </Column>
  );
};

export const withPostDetail = (): Plugin => {
  return {
    kind: "content_detail",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      const content = useMemo(() => {
        const { p } = node;
        if (p) {
          return p as Content;
        }
        return null;
      }, [node]);
      return <>{content ? <PostDetailComponent content={content} /> : null}</>;
    },
  };
};
