import React, { useMemo } from "react";
import {
  Plugin,
  JsonNodePresenterProps,
} from "@teppixdigital/shared-cms-components";

import { Content } from "../../../domain";

export type PostSummary = Pick<Content, "title" | "summary" | "cover_asset_id">;

export const PostSummaryComponent = (props: { post_summary: PostSummary }) => {
  const { post_summary } = props;
  return <div className="post_summary_summary">{post_summary.summary}</div>;
};

export const withPostSummary = (): Plugin => {
  return {
    kind: "content_summary",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      const summary = useMemo<PostSummary | null>(() => {
        const { p } = node;
        if (p) {
          return p as Content;
        }
        return null;
      }, [node]);
      return (
        <>{summary ? <PostSummaryComponent post_summary={summary} /> : null}</>
      );
    },
  };
};
