import React from "react";
import {
  Plugin,
  PostTitle,
  JsonNodePresenterProps,
} from "@teppixdigital/shared-cms-components";

export const withPostTitle = (): Plugin => {
  return {
    kind: "_title_",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      return (
        <PostTitle>
          {({ styleClass }) => (
            <div className={`${styleClass} title_pos`}>{node.p?.text}</div>
          )}
        </PostTitle>
      );
    },
  };
};
