import React, { useEffect, useState } from "react";
import { RouteProps } from "@teppixdigital/shared-router";
import {
  Prose,
  CardPulse,
  JsonNodePresenter,
} from "@teppixdigital/shared-cms-components";

import { FeedItem } from "../../domain";
import { Page, RenderZone } from "../component";
import { getContentDetail, gtm_manager } from "../../use_case";

export type FeedItemDetailFlowProps = RouteProps<{
  item_path?: string;
  item_id?: string;
}>;

export const FeedItemDetailFlow = (props: FeedItemDetailFlowProps) => {
  const { item_path, item_id } = props;

  const [content, setContent] = useState<FeedItem>();
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (item_path && item_id) {
        const resp = await getContentDetail(item_id, item_path);
        if (resp.data) {
          gtm_manager.pushFeedItemPageViewedEvent(resp.data);
          setContent(resp.data);
        }
      }
    };
    load();
  }, [item_path, item_id]);

  return (
    <Page>
      <RenderZone>
        {content && content.body ? (
          <JsonNodePresenter node={content.body} context={{ mode: "public" }} />
        ) : (
          <CardPulse />
        )}
      </RenderZone>
    </Page>
  );
};
