import { FeedItem, GtmEvent } from "../domain";

const getDataLayer = (): GtmEvent[] | undefined => {
  const dataLayer = (window as any)["dataLayer"]; // don't support a custom dataLayer name yet
  if (dataLayer) {
    return dataLayer;
  }
};

const pushEvent = (event: GtmEvent) => {
  const dataLayer = getDataLayer();
  if (dataLayer) {
    dataLayer.push(event);
  }
};

const pushFeedItemPageViewedEvent = (item: FeedItem) => {
  pushEvent({
    event: "feed_item_page_viewed",
    item_id: item.id,
    item_kind: item.kind,
  });
};

const pushFeedItemModelViewedEvent = (item: FeedItem) => {
  pushEvent({
    event: "feed_item_model_viewed",
    item_id: item.id,
    item_kind: item.kind,
  });
};

const pushFeedItemListViewedEvent = () => {
  pushEvent({
    event: "feed_item_list_viewed",
  });
};

export const gtm_manager = {
  pushFeedItemPageViewedEvent,
  pushFeedItemModelViewedEvent,
  pushFeedItemListViewedEvent,
};
