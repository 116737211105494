import React, { useEffect, useState } from "react";
import { RouteProps } from "@teppixdigital/shared-router";

import {
  Prose,
  Column,
  Container,
  CardPulse,
} from "@teppixdigital/shared-cms-components";

import { FeedItem } from "../../domain";
import { getContents, gtm_manager } from "../../use_case";
import { FeedItemSummaryComponent, Page, RenderZone } from "../component";

export type FeedItemListFlowProps = RouteProps<{ category?: string }>;

export const FeedItemListFlow = (props: FeedItemListFlowProps) => {
  const [contents, setContents] = useState<FeedItem[] | null>();

  useEffect(() => {
    const load = async (): Promise<void> => {
      const resp = await getContents();
      if (resp.data && resp.data.items && resp.data.items.length > 0) {
        gtm_manager.pushFeedItemListViewedEvent();
        setContents(resp.data.items);
      }
    };
    load();
  }, []);
  return (
    <Page>
      <RenderZone>
        {contents && contents.length > 0 ? (
          <Column space_between>
            {contents
              .filter((it) => it.body)
              .map((it, index) => (
                <FeedItemSummaryComponent item={it} key={index} />
              ))}
          </Column>
        ) : (
          <CardPulse />
        )}
      </RenderZone>
    </Page>
  );
};
