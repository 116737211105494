import React, { PropsWithChildren } from "react";
import { Header } from "../header/header.component";
import { Column, Container, Prose } from "@teppixdigital/shared-cms-components";

export const Page = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <Column space_between>
      <Header />
      <Prose maxWidth>
        <Container center>{children}</Container>
      </Prose>
    </Column>
  );
};
