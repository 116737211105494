import React, { PropsWithChildren } from "react";
import { ContentPresenterZone } from "@teppixdigital/shared-cms-components";
import {
  withProductDetail,
  withProductSummary,
} from "@teppixdigital/journey-product-client-component";

import { withPostTitle } from "../post_title/post_title.component";
import { withPostContent } from "../post_content/post_content.component";
import { withPostSubTitle } from "../post_subtitle/post_subtitle.component";
import { withPostSummary } from "../post_summary/post_summary.component";
import { withPostDetail } from "../post_detail/post_detail.component";

const plugins = [
  withPostTitle(),
  withPostContent(),
  withPostSubTitle(),

  withPostDetail(),
  withPostSummary(),

  withProductDetail(),
  withProductSummary(),
];

export const RenderZone = (props: PropsWithChildren<{}>) => {
  return (
    <ContentPresenterZone plugins={plugins}>
      {props.children}
    </ContentPresenterZone>
  );
};
