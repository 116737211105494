import React, { useMemo, useState } from "react";
import { XModel } from "@teppixdigital/xuimodel";

import {
  Card,
  CardBody,
  CardHeader,
  JsonNodePresenter,
  LinkInline,
  Links,
  PaddingCss,
  PaddingXCss,
  PaddingYCss,
  Prose,
} from "@teppixdigital/shared-cms-components";

import { routes } from "../../routes";
import { FeedItem } from "../../../domain";
import { FeedItemDetailComponent } from "../feed_item_detail/feed_item_detail.component";

export const FeedItemSummaryComponent = (props: { item: FeedItem }) => {
  const { item } = props;
  const [showDetail, setShowDetail] = useState(false);

  const links = useMemo<Links | null>(() => {
    const { additions } = item;
    if (additions && additions.detail_uri) {
      return {
        detail_uri: routes.feedItemDetailByUriRoute(additions.detail_uri),
      };
    }
    return null;
  }, [item]);

  return (
    <>
      <Card hover_effect show_border={true}>
        <CardHeader>
          {links && links.detail_uri ? (
            <LinkInline url={links.detail_uri} underline={false}>
              <span className="post_summary_title">{item.name}</span>
            </LinkInline>
          ) : (
            <span className="post_summary_title">{item.name}</span>
          )}
        </CardHeader>
        <CardBody
          className="cursor_pointer"
          doOnClick={() => setShowDetail(true)}
        >
          <JsonNodePresenter
            node={item.body}
            node_meta={{ links: links ? links : undefined }}
            context={{ mode: "public" }}
          />
        </CardBody>
      </Card>
      <XModel show={showDetail} onCloseModel={() => setShowDetail(false)}>
        <Prose maxWidth>
          <FeedItemDetailComponent item={item} />
        </Prose>
      </XModel>
    </>
  );
};
