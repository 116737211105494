import React from "react";
import {
  Plugin,
  JsonNodePresenter,
  JsonNodePresenterProps,
} from "@teppixdigital/shared-cms-components";

export const withPostContent = (): Plugin => {
  return {
    kind: "_content_",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      return (
        <>
          {node.p?.content ? (
            <JsonNodePresenter
              node={node.p?.content}
              context={{ mode: "public" }}
            />
          ) : null}
        </>
      );
    },
  };
};
