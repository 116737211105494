const rootPath = "posts";

const feedItemListRoute = () => `${rootPath}`;
const feedItemListByCategoryRoute = (category: string) =>
  `${rootPath}/${category}`;
const feedItemDetailRoute = (id: string, path: string) =>
  `${rootPath}/${id}/-/${path}`;

const feedItemDetailByUriRoute = (uri: string) => `${rootPath}${uri}`;

export const routes = {
  feedItemListRoute,
  feedItemDetailRoute,
  feedItemDetailByUriRoute,
  feedItemListByCategoryRoute,
};
