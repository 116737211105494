import React from "react";
import {
  Plugin,
  PostSubTitle,
  JsonNodePresenterProps,
} from "@teppixdigital/shared-cms-components";

export const withPostSubTitle = (): Plugin => {
  return {
    kind: "_sub-title_",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      return (
        <PostSubTitle>
          {({ styleClass }) => <div className={styleClass}>{node.p?.text}</div>}
        </PostSubTitle>
      );
    },
  };
};
